const colors = {
  primary: '#1992BB', // Color for buttons or links
  bg: '#f8f8f8', // Background color
  white: '#f8f8f8',
  grey: {
    dark: 'rgba(0, 0, 0, 0.9)',
    default: 'rgba(0, 0, 0, 0.7)',
    light: 'rgba(0, 0, 0, 0.5)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
  },
};

const transitions = {
  normal: '0.5s',
};

const fontSize = {
  small: '0.9rem',
  big: '2.9rem',
};

export default {
  colors,
  transitions,
  fontSize,
};
